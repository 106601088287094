.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #00abe1;
  color: #003343;
}

.App-header {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.text-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 75%; /* Adjusts the container width */
  max-width: 1000px; /* Optional: prevents the container from being too wide */
  margin-left: 25%;
  margin-right: 25%;
}

h1 {
  font-size: 3rem;
  color: #003343;
}

h2 {
  font-size: 1.8rem;
  color: #E4580B;
}

footer {
  padding: 1rem;
  background-color: #001116;
}

footer a {
  color: #00abe1;
  text-decoration: none;
  font-size: 0.8rem;
}
